@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=MedievalSharp&display=swap");

#root {
  width: 100vw;
  overflow-x: hidden;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background: #ffffff !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
span,
td,
th,
strong,
a,
div,
input,
label,
select,
option,
textarea {
  font-family: "Poppins", sans-serif;
}

.newsletterBG {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  min-height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.39);
}

.newsInner {
  padding: 20px;
  background: white;
  height: 450px;
  max-height: 60vh;
  width: 900px;
  max-width: 90vw;
  background: #212121;
  box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.349);
  position: relative;
}
.noOpacity {
  opacity: 0;
}
.newsletterImage {
  height: 100%;
  position: absolute;
  left: 0;
}

.swiperBanerTop img {
  width: 100%;
}
.newsInner .error {
  color: rgb(238, 36, 36);
}
.newsInner .success {
  color: rgb(0, 175, 0);
}
.newsInner .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ec6f1b;
  cursor: pointer;
  font-size: 30px;
}
.newsInner .wrapper {
  width: fit-content;
  max-width: 60%;
  margin-left: auto;
  margin-right: 5%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.newsInner .rightSideNewsletter {
  color: white;
}

.newsInner .rightSideNewsletter h2 {
  font-size: 70px;
  text-transform: uppercase;
}

.newsInner .bottom {
  font-size: 20px;
  font-weight: 600;
}
.newsInner .bottom span {
  color: #ec6f1b;
}

.newsInner form {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.newsInner form button {
  font-size: 14px;
  padding: 15px;
  font-weight: 600;
  background-color: #ec6f1b;
  color: #212121;
  border: none;
  height: 50px;
}
.newsInner form input {
  color: #212121;
  padding-left: 10px;
  height: 50px;
}
.newsInner form input:focus {
  outline: none;
}

.newsInner p.top {
  font-size: 14px;
  color: #ec6f1b;
}

.fireworks {
  display: none;
}
.fireworks#active {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
#logoHeader {
  max-height: 80px;
  transition: transform 500ms ease-in-out;
}
#logoHeader:hover {
  transform: scale(1.1);
  transition: transform 500ms ease-in-out;
}
#productGrid .price.bluePromotion {
  color: #0156ff;
}
#productGrid .price s {
  color: var(--body-color, #526484);

  /* muted text nunito */
  font-size: 17px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
  text-decoration-line: strikethrough;
}
#mystery .productItem .price s {
  color: var(--body-color, #526484);

  /* muted text nunito */
  font-size: 17px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
  text-decoration-line: strikethrough;
}
body a {
  text-decoration: none;
}
#topHeader {
  background: #212121;
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: white;
  align-items: baseline;
  font-size: 14px;
}
#topHeader svg {
  font-size: 26px;
}
.rightSideItems svg {
  font-size: 26px;
}
#topHeader .box {
  display: flex;
  gap: 10px;
  align-items: center;
}
#topHeader .box img {
  height: 45px;
  width: auto;
}
#header {
  background: #ffffff;
}

.header-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rightSideItems {
  gap: 15px;
  font-size: 18px;
}
.rightSideItems i {
  font-size: 26px;
}
.search .input-group {
  border-radius: 10px;
  background: #ffffff;
  height: 50px;
}
.search .input-group-text {
  background: #ffffff;
  border-right: none;
}
.search input {
  border-left: none;
}

#footer {
  box-shadow: 0px 0px 20px rgba(221, 221, 221, 0.5);
}

.footerRow {
  justify-content: space-between;
}
.footerRow .socials {
  font-size: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.footerRow .socials i {
  cursor: pointer;
}

.footerRow .links {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.footerRow .links a {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15em;
  color: #212121;
  text-transform: uppercase;
  padding: 0 20px;
}
.mySwiper .swiper-button-next,
.swiper-button-prev {
  color: #212121 !important;
  top: var(--swiper-navigation-top-offset, 69%) !important;
}

.mySwiper .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 30px) !important;
}
.mySwiper .swiper-pagination {
  text-align: left !important;
  margin-left: 2vw;
}
.mySwiper .swiper-pagination-bullet {
  width: 120px !important;
  border-radius: 0 !important;
  height: 20px !important;
}
.mySwiper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 7px var(--swiper-pagination-bullet-horizontal-gap, 10px) !important;
}
.mySwiper .swiper-pagination-bullet {
  background: white !important;
  border: 1px solid #212121 !important;
  opacity: 1 !important;
}
.mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #212121 !important;
}
.stickyTOP {
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 70px;
}
.stickyTOPMargin {
  margin-bottom: 70px;
}
#checkoutTable {
  min-height: 30vh;
}
.checkoutBasket {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wariantSelect {
  max-width: 300px;
  text-align: center;
}
.wariantSelect option {
  text-align: left;
  padding: 4px 7px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #272f3b;
}
.wideCheckout {
  width: fit-content !important;
  padding: 10px 30px !important;
  max-width: 90vw !important;
}
.checkoutPRodImg {
  height: 60px !important;
  width: 60px !important;
  object-fit: contain;
}
.addToBasketNofify {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.addToBasketNofify button {
  height: 35px;
  padding: 2px 33px;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid transparent;
}
.addToBasketNofify .goBasket {
  background: #212121;
  color: white;
}
.comebackShop {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  background: #0156ff;
  border-radius: 50px;
  color: white;
  border: none;
  padding: 10px 40px;
}
.thankYouProdImg {
  max-width: 150px !important;
}
#thankYouRight td,
#thankYouRight th {
  text-align: center;
  vertical-align: middle;
}
#thankYou h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: #000000;
}

#thankYou h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 210%;
  color: #605d5d;
}
.addToBasketNofify .goCheckout {
  border-color: #212121;
  color: #212121;
}
.paczkomatModal {
  max-height: 90vh;
  height: 500px;
}

#thankYou .section {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}
#thankYou h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  color: #1676fb;
}

#section p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #46535a;
}

#section h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #46535a;
}

#thankYouRight {
  background: #f5f7ff;
  border-left: 1px solid #d8d8d8;
}

#categories {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15em;
  box-shadow: 0px 0px 20px rgba(221, 221, 221, 0.5);
}
#categories div a {
  padding: 15px 20px;
}
#categories div {
  padding: 15px 0;
}
.wishlistButton {
  font-size: 25px;
}
.wishlistPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.wishlistPrice p {
  font-size: 22px;
}
.f22 {
  font-size: 22px;
}
#categories div:hover {
  background: #212121;
  color: white !important;
}
#categories div:hover a {
  color: white;
}
.shopSearch {
  background: #000000;
  border: 1px solid #212121;
  width: 100%;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  border-radius: 4px;
}
#sidebar .categories p,
#sidebar .categories li {
  cursor: pointer;
}
h3.error {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
}
.smallTable td {
  font-size: 14px;
}
.tagsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.tag-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  background: #0156ff5d;
  padding: 5px 8px;
  border-radius: 10px;
  color: #212121;
}
.categoryAddProduct {
  max-height: 400px;
  overflow-y: auto;
}
.tag-item .close {
  font-size: 20px;
  color: red;
  cursor: pointer;
}
#categories a {
  font-size: 22px;
  color: #212121;
  font-family: "Poppins", sans-serif;
}

#categories .outletDiv:hover {
  background: #0156ff;
}
#categories a.outlet {
  font-family: "MedievalSharp", cursive;
  color: #0156ff;
}
#categories a.outlet:hover {
  color: white;
}
#categories div {
  cursor: pointer;
}
.brand input {
  accent-color: #212121;
}

.spacer {
  padding: 0.5rem 0;
  text-align: center;
  background-color: #212121;
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 0.7em;
  font-weight: 500;
}
#productGrid {
  position: relative;
  text-align: center;
}
#productGrid .promoCloud {
  position: absolute;
  z-index: 999;
  right: 0;
  background: #0156ff;
  padding: 3px 10px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 10px;
}

#productGrid .productImg {
  width: auto;
  max-width: 100%;
  height: 200px;
  object-fit: contain;
}

.image-gallery-image {
  min-height: 400px;
  object-fit: contain;
}
.image-gallery {
  background: white;
  -webkit-tap-highlight-color: unset;
}
.image-gallery-content.fullscreen {
  background: rgba(255, 255, 255, 0.384) !important;
}
.image-gallery-thumbnail-image {
  height: 60px;
  object-fit: contain;
}
.lubLogin {
  color: var(--gray-400, #b7c2d0);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 165%;
  text-transform: uppercase;
}

.socialsLogin {
  color: #1676fb;
  /* User/p-text/400 */
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: 165%;
}
#productGrid .title {
  margin-bottom: 0;
  text-align: center;
  color: #222222;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#productGrid .price {
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #222222;
  font-weight: 800;
}
#mystery .productItem .price {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #222222;
  font-weight: 800;
}

#mystery .products {
  height: 40vh;
  min-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.TrustedUs .swiper-pagination-bullet {
  background: grey !important;
}
.TrustedUs .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white !important;
}
.codeItemTable th {
  font-size: 12px;
  padding: 0 !important;
}
.influRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.influWrapper {
  width: 33.3%;
}
#sidebar {
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  position: sticky;
  bottom: 1rem;
}

.results,
.sorting {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 26px;
  color: #212121;
  font-family: "Poppins", sans-serif;
}
.sorting {
  position: relative;
}
.brand label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
#sidebar .marka {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#sidebar .marka i {
  font-size: 20px;
}

.faq {
  min-height: 70vh;
}
.sorting .sortingResults {
  padding: 10px 0;
  position: absolute;
  z-index: 9;
  background-color: white;
  top: 40px;
  transition: ease-in-out;
}
.sortingResults {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 10px;
}
.sortingResults div {
  padding: 10px;
  font-size: 18px;
  width: 200px;
  text-align: center;
  cursor: pointer;
}
.sortingResults div:hover {
  background: #212121;
  color: white;
}
.sorting i {
  font-size: 30px;
}
.results div {
  cursor: pointer;
}

.topCat {
  letter-spacing: 3px;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.categories {
  padding-left: 20px;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.categories ul {
  list-style-type: none;
}
.shopMobileFilters {
  padding: 20px;
  display: none;
}
.categories #displayCat {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.85s ease-in;
}
.categories #dontDisplayCat {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.55s ease-out;
}

#brandShow {
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 1s ease-in;
}

#brandDontShow {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}

#sidebar .search .input-group {
  height: 30px;
}
body .search .input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

body .search input.form-control {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.sizesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 7px;
}
.colorGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: start;
}
#misjaText h5 {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 2rem;
  font-style: italic;
}
#misjaText h5 span {
  font-weight: 700;
}
#misjaText p {
  font-size: 19px;
}
#misjaText .outlet {
  font-family: "MedievalSharp", cursive;
  color: #0156ff;
}
.colorGrid #color {
  width: 40px;
  cursor: pointer;
  border: 1px solid black;
  height: 40px;
}
.colorGrid #color.active {
  border: 3px solid #0156ff;
}

.shopSearch {
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
#size {
  text-align: center;
  padding: 2px;
  border: 1px solid #212121;
  background: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: 300;
}
.brand label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
#size:hover,
#size.active {
  background: #212121;
  color: white;
}
.pricesSidebar {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.pricesSidebar input {
  height: 25px;
  width: 40%;
}
section.range-slider {
  position: relative;
  text-align: center;
}

section.range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 15px;
  width: 200px;
  outline: none;
  height: 18px;
  margin: 0;
  padding: 0;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  background: #000;
  border-radius: 10px;
  height: 25px;
}
section.range-slider input::-webkit-slider-runnable-track,
section.range-slider input::-moz-range-track {
  background: #212121 !important;
  height: 5px !important;
}
section.range-slider input::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  background: #212121;
  z-index: 1;
  outline: 0;
}
section.range-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  border: 5px solid #212121;
  cursor: pointer;
}

section.range-slider input::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -moz-appearance: none;
  width: 9px;
}
.brandIMAGEPRODUCT {
  height: 60px !important;
}

section.range-slider input::-moz-range-track {
  position: relative;
  z-index: -1;
  background-color: rgba(0, 0, 0, 1);
  border: 0;
}
section.range-slider input:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none transparent;
  border: 0;
}
section.range-slider input[type="range"]::-moz-focus-outer {
  border: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #212121;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #212121;
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #212121;
}
input[type="range"]::-moz-range-track {
  background-color: #212121;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #212121;
}
input[type="range"]::-ms-fill-upper {
  background-color: #212121;
}

.left p {
  color: #364a63;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 700;
}
.left span {
  color: #8091a7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.comeback {
  border: 1px solid #484848;
  border-radius: 4px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #212121;
  padding: 10px 20px;
  cursor: pointer;
}
.image-gallery-thumbnails {
  margin-top: -30px;
}

#product .title {
  color: #364a63;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
}
#product .ids {
  color: #000000;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
#product .amount .btn:hover {
  background: #212121;
  border-color: #212121;
}

#product .priceD {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
}

#product .price {
  color: #1676fb;
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

#product .old {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #526484;
  text-decoration: line-through;
}
#product .additional {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#product .additional .first {
  color: #8091a7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
}

#product .additional .answer {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #364a63;
}

#product .additional .green {
  color: #20c997;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

#product .description {
  color: #8091a7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17.5px;
}
#product .addToCart {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#product .addToCart .amount {
  display: flex;
  flex-direction: row;
}
#product .addToCart .amount .input-group {
  width: unset;
  height: 40px;
}
#product .addToCart .amount input {
  width: 50px !important;
}

#product .basketAdd {
  height: 40px;
  padding: 5px 20px;
  background: #212121;
  border-radius: 3px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 13px;
}

#desc h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.48px;

  color: #212121;
}

#desc .descriptionMain {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #212121;
}
body a {
  color: #212121;
}
#product h6 {
  color: #212121;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 28px;
}

#related #productGrid .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
}
.fvAdmin {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  background: red;
}
.fvAdmin#active {
  background: green;
}
.checkoutBasket {
  background: #f5f6fa;
  padding: 20px;
  height: 100%;
}
.checkoutOrder {
  width: 100%;
  background: #0156ff;
  border-radius: 50px;
  padding: 10px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
}
.checkoutBack {
  width: 100%;
  border: 2px solid #a2a6b0;
  border-radius: 50px;
  padding: 10px;
  font-size: 14px;
}
.circleB {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
}
.basketControl {
  height: 100%;
}
.circleB div {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #cacdd8;
  border-radius: 50%;
  color: #a2a6b0;
  font-size: 22px;
  cursor: pointer;
}
.checkoutButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.comebackB {
  border: 2px solid #a2a6b0;
  border-radius: 50px;
  padding: 7px 20px;
  width: 200px;
  font-weight: 600;
  font-size: 14px;
  font-size: 14px;
  background: white;
  height: 40px;
}
.baksetPrice input,
.baksetPrice input:active,
.baksetPrice input:focus {
  width: 60px;
  background: #f5f7ff;
  border-radius: 6px;
}
.checkoutClear {
  background: #000000;
  border: none;
  width: 200px;
  height: 40px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-size: 14px;
  border-radius: 50px;
  padding: 7px 20px;
}

#bottomBasket {
  text-align: center;
  background: #f5f6fa;
}
#bottomBasket p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 1rem;
  font-size: 18px;
  color: #212121;
}
#bottomBasket img {
  max-height: 90px;
}
.imageB img {
  height: auto;
}
.imageB {
  max-width: 200px;
}
#items .table,
.baners table {
  table-layout: fixed;
}
.swiper-wrapper {
  text-align: center;
}
table td,
table th {
  overflow: hidden;
  text-overflow: ellipsis;
}
#items .table td,
#items .table th {
  vertical-align: middle;
}
.orderItemImg {
  max-width: 35px !important;
  margin-right: 4px;
}
.removeaccMess {
  background: rgba(237, 203, 80, 0.08);
  border: 1px solid rgba(237, 203, 80, 0.35);
  border-radius: 18px;
  padding: 20px;
  color: #eebb04;
}
#notFound {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#notFound h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #364a63;
}
#notFound p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #526484;
}

#notFound button {
  background: #1676fb;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  padding: 8px 15px;
  color: white;
  border: none;
}
.reddot {
  color: #c94d3f;
}

#cOrder label {
  font-weight: 600;
  font-size: 16px;
}

#cOrder .checkMW {
  max-width: 400px;
}

#cOrder .topSection {
  font-weight: 600;
  font-size: 32px;
  color: #000000;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}
#cOrder .topSection button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  padding: 8px 20px;
  border: 2px solid #1676fb;
  background: white;
  border-radius: 50px;
  width: 200px;
  color: #1676fb;
  height: 50px;
}

#cOrder h2 {
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  color: #000000;
}
.gap20 {
  gap: 20px;
}
.gap10 {
  gap: 10px;
}
.pItem {
  align-items: center;
}
.pItem p {
  margin-bottom: 0;
}

.cBasket {
  background: #f5f7ff;
  padding: 20px;
}

.checkoutNext {
  background: #0156ff;
  border-radius: 50px;
  width: 200px;
  max-width: 100%;
  padding: 7px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  border: none;
  color: white;
}

/* Admin */
.partner .sidebar {
  background: #212121;
}

.sidebar {
  position: fixed;
  background: #f5f6fa;
  min-height: 100vh;
  overflow-y: auto;
  left: 0;
  justify-content: flex-start;
  gap: 5vh;
  display: flex;
  flex-direction: column;
}

.sidebar h5 {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #8091a7;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin: 20px 0;
}

.sidebar .sidebarItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding: 5px 10px;
  margin: 5px 0;
  color: #526484;
  font-weight: 700;
  border-radius: 30px;
}
.white {
  color: white;
}
.settingsPartner {
  margin-top: 1rem;
  display: flex;
  justify-content: start;
  gap: 2rem;
  min-height: 90vh;
}
.innerSettingsPartner {
  height: fit-content;
  padding: 20px;
  max-width: 90vw;
  width: 500px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  text-align: center;
}
.rangiSection .bronze#active {
  background: #6e4030;
  color: white;
}

.rangiSection .silver#active {
  background: #c0c0c0;
  color: white;
}

.rangiSection .gold#active {
  background: #ffae00;
  color: white;
}
.rangiSection .range {
  padding: 10px;
  border-radius: 10px;
}
.copyCodeLink button {
  padding: 10px 20px;
  border: 1px solid black;
  background: white;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.copyCodeLink button svg {
  font-size: 25px;
}

.partner h1 {
  color: #fff;
  font-size: 30px;
}
.partner .sidebar .sidebarItem {
  color: #fff;
}
.partner .sidebar h5 {
  color: white;
}
.partner .sidebar .sidebarItem#active,
.partner .sidebar .sidebarItem:hover {
  background: white;
  color: #1676fb;
}
.sidebar .sidebarItem svg,
.sidebar .sidebarItem i {
  font-size: 28px;
}
.sidebar .sidebarItem#active,
.sidebar .sidebarItem:hover {
  background: white;
  color: #1676fb;
}
.sidebar .sidebarItem#active i,
.sidebar .sidebarItem:hover i {
  color: #526484;
}

img.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

#panelRight img.circle {
  height: 70px;
  width: 70px;
}
.f20 {
  font-size: 20px;
}
.whiteBG {
  background-color: white !important;
  border-left: none !important;
}
.noborR {
  border-right: none !important;
}
.g10 {
  gap: 10px;
}
.g20 {
  gap: 20px;
}
.topSiderbarContent {
  height: 45px;
}
.topSiderbarContent p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.topSiderbarContent p.blue {
  color: #1676fb;
}

.blue {
  color: #1676fb;
}
.bottomAdmin {
  height: 25px;
  padding-top: 10px;
  border-top: 1px solid #dbdfea;
}
.contentAdmin {
  min-height: calc(100vh - 130px);
}

.contentAdmin section {
  border: 1px solid #dbdfea;
  border-radius: 4px;
  padding: 20px;
  background: white;
}

.contentAdmin h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #364a63;
  margin-bottom: 0;
}

.contentAdmin .topP {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8091a7;
}
.contentAdmin p.mapper {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.168px;
  color: #526484;
  margin-bottom: 0;
}
.contentAdmin section > span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8091a7;
}
.contentAdmin section h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  color: #526484;
}
.red {
  color: #e85347;
}
.green {
  color: #1ee0ac;
}

.contentAdmin .smaller {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #8091a7;
}
.contentAdmin .blue {
  color: #1676fb;
}

.contentAdmin .userImage {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e1edfe;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  text-transform: uppercase;
  color: #1676fb;
}

.contentAdmin .user {
  border-bottom: 1px solid #dbdfea;
}

.contentAdmin #status {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  word-break: keep-all;
}
.contentAdmin .tableHomePage {
  border-top: 1px solid #dee2e6;
  table-layout: fixed;
  vertical-align: middle;
}
.contentAdmin .tableHomePage thead th {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8091a7;
}

.contentAdmin .tableHomePage .numerOrder {
  width: 80px;
}
.mobileSidebar {
  display: none;
}
.mainButton {
  background: #ffffff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  padding: 10px 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.26px;
  color: #526484;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 17px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mainButton i {
  font-size: 25px;
}
.banerTableImg {
  max-width: 300px !important;
}
table.baners {
  table-layout: fixed;
}
.baners tbody td {
  vertical-align: middle !important;
}

.addBaner {
  max-width: 700px;
  margin: auto;
}

.b1920 {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #1676fb;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #1676fb;
}
.addBanerText {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.84px;
  color: #364a63;
}
.codes {
  max-height: 60vh;
  overflow: auto;
}
.imageHolder {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  padding: 20px 30px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.bButton {
  width: 200px;
  max-width: 100%;
  padding: 10px 20px;
  background: #1676fb;
  border: 1px solid #1676fb;
  border-radius: 3px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #ffffff;
}

.noResults {
  display: flex;
  text-align: center;
  width: 100%;
  height: 50vh;
  font-size: 30px;
  align-items: center;
  justify-content: center;
}

.usersTable {
  background: #ffffff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  padding: 10px;
}
.usersTable table {
  table-layout: fixed;
}
.usersTable table tbody td,
.usersTable table tbody th {
  vertical-align: middle;
}
.selectorTH {
  width: 35px;
}
.selectorTHL {
  width: 100px;
}
.selector {
  accent-color: #212121;
}
#products .selectorTH {
  width: 40px;
}
#products .productImage img {
  width: 100px !important;
  height: 40px;
  object-fit: contain;
}
.selector {
  height: 15px;
  width: 15px;
  border: 2px solid #212121;
  background: white;
}

.selector#active {
  background: #212121;
}

.usersTable thead {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #8091a7;
}

.usersTable tbody {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #364a63;
}

.filtersUsers {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.filtersUsers button {
  height: 45px;
  width: 220px;
}
.black {
  color: #212121;
}
.dropDownCustom {
  position: relative;
}
.selectedTakso {
  width: 100%;
}
.dropDownItems {
  position: absolute;
  width: 100%;
  background: #eeeeee;
  padding: 10px 10px;
}
.dropdownItems input[type="checkbox"] {
  accent-color: #212121;
}
input[type="checkbox"] {
  accent-color: #212121;
}
.removeCat {
  cursor: pointer;
}
.dropDownItems label {
  display: flex;
  flex-direction: row;
  gap: 10px;
  accent-color: #212121;
}
.filtersUsers input[type="text"] {
  height: 45px;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  width: 250px;
  letter-spacing: 0.26px;
  color: #526484;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 17px;
}
.pages {
  display: flex;
  flex-direction: row;
  gap: 0;
}
.pages .numer {
  background: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dbdfea;
}
#contact {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#contact h3 {
  color: #212121;
  font-family: "Poppins", sans-serif;
  font-size: 23px;
}
.pages .numer#active {
  background: black;
  border-color: black;
  color: white;
}
.paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.pages .prev {
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #dbdfea;
}

.selectPage {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.selectPage input {
  height: 30px;
  width: 50px;
}

.userSection {
  border: 1px solid #dbdfea;
}
.userSection .head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 20px;
  border-bottom: 1px solid #dbdfea;
}
.userSection .head div {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding: 15px;
}
.userSection .head div#active,
.userSection .head div:hover {
  color: #1676fb;
  border-color: #1676fb;
}

.note .text {
  padding: 20px;
  background: #f5f6fa;
  border-radius: 6px;
}
.userImageProfile {
  padding: 20px;
  background: url("./assets/images/userDataBg.png") top no-repeat;
}
.userImageProfile img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.userImageProfile p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: #333843;
}
.userImageProfile .userBadge {
  background: #efeffd;
  border-radius: 100px;
  width: fit-content;
  margin: auto;
  padding: 4px 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #1676fb;
}

.tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.tab p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #364a63;
}
#informationsUser h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #8091a7;
}
.tab p.light {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #8091a7;
}
.accepted {
  background: #e9fcf7;
  border: 1px solid #e9fcf7;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 3px 6px;
  line-height: 20px;
  letter-spacing: 0.24px;
  color: #1ee0ac;
  width: fit-content;
}
.orders thead {
  background: #f5f6fa;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #8091a7;
}
.orders h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #364a63;
}
#productAdd .addTop {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}
#productAdd h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #1a1c21;
}
#productAdd .image {
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 130px;
  text-align: center;
}
#productAdd h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #4d5464;
}
#productAdd .imageInner {
  object-fit: contain;
  position: relative;
  height: 130px;
}
.dragged img {
  margin: auto;
  max-height: 130px;
}
.dragged i {
  display: none;
}
#productAdd .imageInner img,
#productAdd .dragged img {
  height: 95%;
  object-fit: contain;
}
#productAdd .removeGalleryItem {
  color: red;
  font-size: 40px;
  position: absolute;
  right: 0;
}
#productAdd .images #first {
  background: #1676fb;
}

#productAdd .images svg {
  font-size: 30px;
}

#productAdd .deliverySelect {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dropDownContainer {
  position: relative;
}
.selectedTakso {
  position: absolute;
  background: white;
  padding: 20px 10px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

table#products {
  min-height: 15vh !important;
}
#productAdd .deliverySelect label {
  color: #1676fb;
}
#productAdd .deliverySelect input {
  accent-color: #1676fb;
}
#productAdd .chooseCategory {
  background: #f9f9fc;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #333843;
}

#productAdd .addProductButton {
  background: #f9f9fc;
  border: 2px solid #e0e2e7;
  border-radius: 8px;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #333843;
}
#productAdd .mainButton#active {
  background: #1676fb;
  color: white;
}
.wariantButtons button {
  padding: 8px 13px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.005em;
}

.wariantButtons .addWariant {
  background: #dedefa;
  color: #5c59e8;
}

.wariantButtons .addAllAtri {
  background: #1676fb;
  color: white;
}

.wariantButtons .onePrice {
  background: #000000;
  color: white;
}

#removeWariant {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
}
.buttonRemove {
  width: 40px;
}
.removeWariant {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcdad7;
  border-radius: 8px;
  color: #f04438;
  border: none;
  font-size: 26px;
}
.brandImageTable img {
  max-height: 60px;
}
.inputRemove {
  width: calc(100% - 40px);
}
.breakWords {
  word-wrap: break-word;
}
.f500 {
  font-weight: 500;
}
.f300 {
  font-weight: 300;
}
.f400 {
  font-weight: 400;
}
.f600 {
  font-weight: 600;
}
.actionsTable {
  font-size: 28px;
}
.atrybutyBOXY .fB {
  font-size: 16px;
}
.fB {
  font-size: 20px;
}
.atribute {
  width: fit-content;
}
.markaAdd h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #1a1c21;
}
.imageContainerMarka i {
  font-size: 40px;
  margin-bottom: 20px;
  color: #1676fb;
  padding: 10px;
  background: #efeffd;
  border-radius: 50%;
}

.productItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}
.oneRowFiltersBox .filters {
  cursor: pointer;
  font-size: 18px;
}
.oneRowFiltersBox .filters p#active {
  font-weight: 700;
}

.smallButtonClick {
  width: 20px;
  height: 20px;
  border: 2px solid black;
  background: white;
}
.boxTableImg {
  max-width: 100px !important;
  max-height: 50px !important;
  object-fit: contain;
  text-align: center;
}
.smallButtonClick#active {
  background: black;
}
.productItem .productContent {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
}
.productItem .productContent img {
  width: 80px;
  max-height: 30px;
  object-fit: contain;
}
.contentAdmin #mystery section {
  padding: 10px;
}
.productItem .productContent p {
  font-size: 14px;
}
.addManyItemsModalOverflow {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background: #0000003f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalInner {
  width: 500px;
  max-width: 90vw;
  max-height: 100vh;
  height: 400px;
  padding: 20px;
  background: white;
  text-align: center;
  position: relative;
}
.modalInner svg {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
.modalInner img {
  max-height: 60%;
  max-width: 300px;
}
.modalADDMany {
  margin-left: 10px;
  font-size: 26px;
  cursor: pointer;
}
.productItem .price.bluePromotion {
  color: #0156ff;
}
.atributesExamples {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
}
.filtersOrders {
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}
.during {
  color: #09c2de;
}

.yellow {
  color: #f39527;
}
.selectorOR {
  width: 65px;
}
.changeStatus #active {
  background: #212121;
  color: white;
}
.wrenchOrder {
  overflow: visible;
  position: relative;
  width: 40px;
  font-size: 22px;
  color: #fa8a39;
  cursor: pointer;
}
.changeStatusFlex {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.changeStatusFlex1col {
  display: grid;
  grid-template-columns: 1fr;
}
.changeStatus .changeStatusFlex div {
  width: 100%;
  text-align: center;
}
.changeStatus div {
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 18px;
  cursor: pointer;
}
.wrenchOptions {
  position: absolute;
  right: 0;
  background-color: white;
  z-index: 999;
  width: 200px;
  border: 1px solid #5264847c;
  border-radius: 10px;
}
.wrenchOptions h5 {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  text-align: center;
  color: #526484;
}
.wrenchOptions div {
  color: #8091a7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 165%;
  padding: 5px;
}
.wrenchOptions div:hover {
  background: #8091a7;
  color: white;
}
#singleOrder h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.84px;
  color: #364a63;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
}
#singleOrder h2 div {
  font-size: 15px;
  padding: 4px 10px;
  background: #fdf1e8;
  border-radius: 20px;
}
#singleOrder .tabOrder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
}
#singleOrder .tabOrder div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #1a1c21;
}
#singleOrder .tabOrder div i {
  font-size: 25px;
  padding: 8px;
  background: #e0e2e7;
  border-radius: 50%;
  color: #667085;
}
.tabOrder p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #1a1c21;
}
.singleProductData p {
  font-size: 13px;
  white-space: normal;
}
#singleOrder .productImage {
  width: 40px;
}

.productShort {
  width: 130px;
}
.productDataSec {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.singleProductData {
  max-width: 150px;
}

.adresTab {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.statusOrder#green {
  background: #e7f4ee;
  color: #0d894f;
}
.adresTab i {
  font-size: 25px;
  padding: 8px;
  background: #e0e2e7;
  border-radius: 50%;
  color: #667085;
}
.adresTab i#success {
  font-size: 25px;
  padding: 8px;
  background: #dedefa;
  border-radius: 50%;
  color: #1676fb;
}

.boxTableImg {
  max-height: 100px;
}
.boxUploadImg {
  max-height: 200px;
}

.categoryName {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.categoryName i {
  font-size: 10px;
}
.wFit {
  width: fit-content;
}
.pointer {
  cursor: pointer;
}
.ReactModal__Content {
  max-width: 90%;
  width: 500px;
}
.backModal {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.backModal i {
  font-size: 30px;
}
.filtersUsers .rachunki {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #364a63;
}

.addFAQ {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 700px;
  max-width: 90vw;
  margin: auto;
}
.addFAQ .ck.ck-editor {
  width: 100%;
}
.addFAQ input:first {
  width: 100%;
}

.buttonFaq {
  padding: 5px 20px;
  border: none;
  font-size: 15px;
  border-radius: 10px;
}
.accordion-button:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: inherit;
}
.accordion-item {
  margin: 15px 0;
}

button.accordion-button:focus {
  box-shadow: inherit;
}
.accordion-button {
  border-bottom: 1px solid rgba(0, 0, 0, 0.363) !important;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  box-shadow: none !important;
  background-color: white !important;
  border-bottom: 1px solid black;
  border-bottom: 1px solid black !important;
  font-weight: 700;
}
.buttonFaq.active {
  background: #0d894f;
  color: white;
}
.buttonFaq.notActive {
  background: rgb(238, 36, 36);
  color: white;
}

.addFAQ .ck.ck-editor__main > .ck-editor__editable {
  min-height: 400px;
}
.addCategory {
  width: 600px;
  margin: auto;
}
.sidebarUser {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #f5f7ff;
}
.sidebarUser hr {
  margin: 5px !important;
}
#panelRight {
  min-height: 55vh;
}
#panelRight h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #121519;
}
#panelRight h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #121519;
}
#panelRight h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #121519;
}
#panelRight h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #121519;
}

#panelRight .light {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #576071;
}

.buttonPanel {
  background: #cbcdcf;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 25px;
  border: none;
  color: #30343b;
}

.buttonPanel.red {
  background: #ed5050;
  color: white;
}
.sidebarUser a {
  padding: 0 15px;
  color: #212121;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.f30 {
  font-size: 30px;
}
.sidebarUser a.active,
.sidebarUser a:hover {
  font-weight: 700;
  border-left: 3px solid #0156ff;
}
.customSelect {
  width: 100%;
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 10px;
  position: relative;
}
.customSelect:hover .SelectDropdown {
  display: flex;
  position: absolute;
  top: 45px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  background: #ffffff;
  left: 0;
  width: 100%;
  flex-direction: column;
}
.SelectDropdown {
  display: none;
}
.dropdownItem {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.dropdownItem:hover {
  background: #e0e0e0;
}
.influImg {
  width: 60px;
  object-fit: contain;
  height: 60px;
}
.addCategory h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.loginRow {
  height: 100vh;
  width: 100vw;
}
#bgLogin {
  background-color: #f5f6fa;
}
.trustedUsImg {
  width: 100%;
  height: 500px;
  object-fit: contain;
}
.loginSide {
  display: flex;
  flex-direction: column;
}
#loginContainer {
  height: 90vh;
}
.bottomContainer {
  height: 10vh;
}
.contentBottom {
  padding: 20px 0;
  max-width: 600px;
  margin: auto;
  margin-right: 0;
}
.stopkaGrupaLucrum {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.stopkaGrupaLucrum a {
  color: #212121;
}
.sliderLogin {
  max-width: 400px;
  margin: 0 auto;
}
.sliderLogin h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.48px;
  color: #364a63;
  text-align: center;
}
.sliderText {
  max-width: 400px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 165%;
  text-align: center;
  color: #526484;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  color: #dbdfea;
  cursor: pointer;
}

.controls i#active {
  color: #8091a7;
}
#loginContainer {
  display: flex;
  justify-content: center;
}

#loginContainer h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.48px;
  color: #364a63;
}

#loginContainer h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 165%;
  color: #364a63;
}

#loginContainer h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 165%;
  text-align: center;
  text-transform: uppercase;
  color: #b7c2d0;
}

.loginButton {
  background: #1676fb;
  border-radius: 3px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #f5f6fa;
  width: 100%;
  height: 40px;
}

.loginLogin {
  max-width: 500px;
  margin: auto;
  margin-right: 8vw;
  width: 400px;
}
.rightSideLogin {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginLogin h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 165%;
  color: #526484;
}

#hide {
  display: none;
  transition: ease-in-out;
}

#show {
  display: block;
  transition: ease-in-out;
}
#ordersFilters #active {
  border-bottom: 1px solid #212121;
}
.mobileTitleProduct {
  display: none;
}
.onlyMobile {
  display: none !important;
}
.paczkomatModalMobile {
  display: none;
}
.sidebar_toggleAdmin {
  font-size: 32px;
}
.mobileMenuSidebar {
  display: none;
}

.checkoutButtonsB {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  gap: 10px;
}
.google-pay-button-container {
  height: 40px;
}

.promoCodeButton input {
  width: 70%;
}
.promoCodeButton button {
  width: 30% !important;
  border-radius: 0;
}

.userBoxMystery {
  text-align: center;
}
.userBoxMystery img {
  height: 200px;
  object-fit: contain;
}

.sizesSelect {
  display: flex;
  gap: 10px;
  font-size: 16px;
  flex-direction: row;
  flex-wrap: wrap;
}
.sizesSelect input {
  margin-right: 10px;
}
#panelRight .userBoxMystery h4 {
  font-size: 15px;
  margin: 10px 0;
}
#panelRight .userBoxMystery button {
  border-radius: 12px;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 25px;
  border: none;
  color: #30343b;
}
.userBoxMystery.use {
  border-radius: 12px;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 25px;
  border: none;
  background: #0156ff;
  color: white;
}
.roulette-pro-regular-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
#panelRight .userBoxMystery button.used {
  background: #cbcdcf;
}
#panelRight .userBoxMystery button.use {
  background: #0156ff;
  color: white;
}
.spinnerWrapper {
  min-height: 65vh;
  margin: auto;
}
.roulette-pro-regular-prize-item-wrapper {
  background: white !important;
  border: none !important;
}
.successLoteryModal {
  position: fixed;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000009c;
  z-index: 99999;
}
.ImageSuccess {
  background-color: white;
  border-radius: 10px;
  text-align: center;
}

.partnerJoin {
  background: #eee;
  min-height: 100vh;
}

.partnerJoin .row {
  width: 100vw;
  overflow-x: hidden;
}

.partnerJoin .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partnerJoin .top .login {
  background: #000000;
  border: 1px solid #0000;
  border-radius: 4px;
  padding: 10px 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.26px;
  color: #ffff;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 17px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.partnerJoin .leftPartner {
  background: #000;
  min-height: 100vh;
}
.partnerJoin .leftPartner .logoDiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnerJoin .leftPartner .logoDiv H1 {
  font-size: 10vh;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 100%;
  text-shadow: #fff 0px 0px 10px;
  transform: rotate(-90deg);
  color: white;
}

.partnerJoin .contentPartnerRight {
  font-size: 2vh;
}

.partnerJoin .leftPartner .logoDiv span.active {
  text-shadow: #eee 0px 0px 10px;
  color: #000;
}

.partnerJoin .leftPartner .logoDiv span.active.blue {
  text-shadow: #0156ff 0px 0px 10px;
  color: #000;
}

.partnerJoin .contentParner {
  padding: 20px;
}

.partnerJoin .contentParner h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 3vh;
}
.partnerJoin .description > p {
  font-size: 2.4vh;
  text-align: center;
  font-style: italic;
}

.partnerJoin .howItWorks {
  margin-top: 3rem;
}

.partnerJoin .howItWorks h2 {
  font-size: 2.7vh;
  color: #212121;
}

.partnerJoin .pointsHow {
  display: flex;
  padding: 15px 10px;
  border: 1px solid #cecece;
  flex-direction: column;
  gap: 3vh;
}

.partnerJoin .pointsHow .pointHow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.partnerJoin .pointsHow .pointHow .title {
  width: 20%;
  font-weight: 700;
  font-size: 1.7vh;
}

.partnerJoin .pointsHow .pointHow .desc {
  width: 70%;
  font-size: 1.5vh;
}

.partnerJOIN.modalBG {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background: #00000050;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnerJOIN .content {
  padding: 20px;
  background: white;
  max-width: 90vw;
  width: 600px;
  position: relative;
}

.partnerJOIN .close {
  top: 0;
  right: 5px;
  position: absolute;
  color: red;
  font-size: 30px;
  cursor: pointer;
}

.partnerJOIN .content h4,
.innerSettingsPartner h4 {
  font-size: 22px;
  text-align: center;
}

.partnerJOIN .content form,
.innerSettingsPartner form {
  display: flex;
  flex-direction: column;
}

.partnerJOIN .content form p,
.innerSettingsPartner form p {
  margin-top: 0.6rem;
}

.partnerJOIN .content form input,
.innerSettingsPartner form input {
  height: 35px;
  padding: 10px;
}
.partnerJOIN .content form input:focus,
.innerSettingsPartner form input:focus {
  outline: none;
}

.partnerJOIN .content form .policy a {
  color: #0156ff;
}

.partnerJOIN .content form button,
.innerSettingsPartner form button {
  height: 45px;
  background: #000000;
  border: 1px solid #0000;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.26px;
  color: #ffff;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
}

.partnerJOIN input::-webkit-outer-spin-button,
.innerSettingsPartner input::-webkit-outer-spin-button,
.innerSettingsPartner input::-webkit-inner-spin-button,
.partnerJOIN input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.partnerJOIN input[type="number"],
.innerSettingsPartner input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.modalBackdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1;
  cursor: pointer;
  width: 100vw;
  background: #00000050;
}

.modalKod {
  z-index: 2;
  width: 600px;
  max-width: 90vw;
  height: 440px;
  min-height: fit-content;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
button.mainButton {
  cursor: pointer;
}
.modalKod.remove {
  height: 150px;
  min-height: fit-content;
  width: 300px;
  min-width: fit-content;
}

.modalKod h4 {
  font-size: 20px;
  color: #1a1c21;
  letter-spacing: 1px;
}

.dropdownfILTERS {
  position: relative;
}

.dropdownfILTERS .mySuperDropdown {
  position: absolute;
  top: 120%;
  background: white;
  border: 1px solid #0000003f;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100vw;
}

.dropdownfILTERS .mySuperDropdown .item {
  font-size: 18px;
  padding: 15px 10px;
  border-bottom: 1px solid #0000003f;
  font-weight: 600;
  cursor: pointer;
}
.dropdownfILTERS .mySuperDropdown .item:hover {
  background-color: #0000003f;
  color: white;
}

@media only screen and (max-width: 1670px) {
  #categories a {
    font-size: 22px;
  }
  #categories div a {
    padding: 10px 15px;
  }
}

/*@media only screen and (max-width: 1550px) {*/
/*  #categories {*/
/*    gap: 60px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1420px) {*/
/*  #categories {*/
/*    gap: 40px;*/
/*  }*/
/*}*/

@media only screen and (max-width: 1420px) {
  #categories {
    gap: 10px;
  }
  #categories a {
    font-size: 20px;
  }
  #categories div {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1280px) {
  #categories div {
    padding: 5px;
  }
}

@media only screen and (max-width: 1120px) {
  #categories div a {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 1050px) {
  .shopMobileFilters {
    display: block;
  }
  #categories div a {
    padding: 2px 5px;
  }
  #categories {
    gap: 10px;
  }
}
.smallFontButton {
  font-size: 15px !important;
}
@media only screen and (max-width: 992px) {
  .partnerJoin {
    width: 100%;
    overflow-x: hidden;
  }
  .partnerJoin .row {
    width: unset;
    padding: 0 0.75rem;
  }
  body .partnerJoin .top {
    flex-direction: column;
    gap: 10px;
  }
  body .partnerJoin .top a {
    width: 100%;
  }
  body .partnerJoin .top button,
  body .partnerJoin .top button.login {
    padding: 10px 10px;
    width: 100%;
    justify-content: center;
    font-size: 16px;
  }
  .partnerJoin .leftPartner {
    padding: 0 10px;
    min-height: unset;
    height: fit-content;
  }
  .partnerJoin .leftPartner .logoDiv H1 {
    margin-bottom: 0;
    transform: unset;
    padding: 3rem 0;
    font-size: 8vw;
  }
}

@media only screen and (min-width: 768px) {
  .only_mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  #product .addToCart {
    flex-wrap: wrap;
  }
  .dostawaLabel {
    text-align: left;
  }
  .partnerJoin .contentParner h1 {
    font-size: 24px;
  }
  .partnerJoin .description > p {
    font-size: 18px;
  }
  .partnerJoin .login {
    background: #000000;
    border: 1px solid #0000;
    border-radius: 4px;
    padding: 10px 30px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.26px;
    color: #ffff;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 17px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .oneRowFiltersBox .filters {
    flex-direction: column !important;
    gap: 5px !important;
    align-items: center;
  }
  .rangiSection .range img {
    max-height: 150px;
  }
  .partnerJoin .pointsHow .pointHow {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  #panelRight .oneRowFiltersBox {
    flex-direction: column !important;
    gap: 20px;
    justify-content: center !important;
  }
  .partnerJoin .pointsHow .pointHow .title,
  .partnerJoin .pointsHow .pointHow .desc {
    width: 100%;
  }
  #panelRight .userBoxMystery button {
    padding: 5px 14px;
    font-size: 16px;
  }
  .checkoutButtonsB {
    flex-direction: column;
  }
  #cOrder .topSection {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .trustedUsImg {
    height: 400px;
    object-fit: cover;
  }
  .loader {
    max-width: 60vw !important;
    margin: 5vh auto !important;
    height: 50vh !important;
    max-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bottomBasket img {
    max-height: 70px;
  }
  .mobileBName {
    font-size: 14px;
  }
  .paczkomatModal {
    display: none;
  }
  .paczkomatModalMobile {
    display: block;
    min-height: 400px;
  }
  .sidebarUser {
    margin-bottom: 20px;
  }
  .paczkomatModalMobile iframe {
    height: 500px !important;
  }
  body #product .left h1 {
    font-size: 20px;
  }
  .wideCheckout {
    width: 100% !important;
  }
  .noMobile {
    display: none !important;
  }
  .comeback {
    padding: 5px 5px;
    width: fit-content;
  }
  .onlyMobile {
    display: block !important;
  }
  table .amount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
  }
  table .amount .input-group {
    flex-wrap: nowrap;
  }
  table .amount .input-group .btn {
    padding: 5px;
  }
  #desc {
    text-align: center;
  }
  #product .priceD {
    justify-content: center;
  }
  .addToBasketNofify {
    justify-content: center;
  }
  #product .additional {
    justify-content: center;
  }
  .mobileTitleProduct {
    display: block;
    font-size: 22px;
  }
  #product .addToCart {
    justify-content: center;
  }
  .wariantSelect {
    width: fit-content;
    margin: 10px auto;
  }
  .no_mobile {
    display: none !important;
  }
  .loginRow {
    margin: 0;
    --bs-gutter-x: 0 !important;
  }
  #bgLogin {
    padding: 10px 20px;
  }
  .loginSide {
    min-height: calc(100vh - 20px);
  }
  .loginSide .contentBottom {
    padding: 0 !important;
    width: fit-content;
    flex-wrap: wrap;
  }
  #productGrid .title {
    font-size: 16px;
  }
  #productGrid .price {
    font-size: 15px;
  }
  .mySwiper .swiper-pagination-bullet {
    width: 50px !important;
    height: 8px !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 24px !important;
  }
}

.fullSizeMobile {
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 99999;
}
.searchMobile .input-group {
  justify-content: center;
  flex-wrap: nowrap;
}

.searchMobile .input-group-text {
  background: white;
  border: none;
  padding: 0;
}
.searchMobile .input-group-text svg {
  font-size: 25px;
}
.searchMobile .input-group .form-control {
  border: none !important;
  border-bottom: 1px solid black !important;
}
.itemsMobile {
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.itemsMobile a {
  color: #222222;
}
.fullSizeMobile {
  display: none;
}
.styleDankycase {
  font-family: "Bricolage Grotesque", sans-serif !important;
}

.adminSidebar {
  overflow-y: auto;
  max-height: 80vh;
}
#shop {
  min-height: 60vh;
}
#shopSidebar {
  height: fit-content;
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 991px) {
  .promoCodeButton input {
    width: 100%;
  }
  .promoCodeButton button {
    width: 100% !important;
    border-radius: 0;
  }

  #items .table,
  .baners table {
    table-layout: auto;
  }
  .table-responsive table {
    table-layout: auto !important;
  }
  .mobileMenuSidebar {
    display: block;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    height: 100vh;
    background: white;
    z-index: 99999;
  }
  .mobileMenuSidebar .adminSidebar {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .mobileMenuSidebar .adminSidebar a {
    font-size: 22px;
    color: #212121;
  }
  .mobileMenuSidebar .sidebarItem {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .mobileMenuSidebar .sidebarItem i {
    font-size: 30px;
  }
  .contentBottom {
    padding: 10px 20px !important;
    margin: auto;
  }
  .contentBottom div {
    justify-content: center;
  }
  .stopkaGrupaLucrum {
    justify-content: center;
  }
  .fullSizeMobile {
    display: block;
  }
  #shopSidebar {
    display: none;
  }
  body .mobileTopShop {
    justify-content: flex-start !important;
    gap: 10px;
  }
  body .mobileTopShop span {
    font-size: 18px;
  }
  body .mobileTopShop span i {
    font-size: 22px;
  }
  .filterMobile {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
.mobileMenu {
  display: none;
}
@media only screen and (max-width: 1199px) {
  #product .left h1 {
    font-size: 26px;
  }
  .sidebar,
  .topSiderbarContent {
    display: none;
  }
  .mobileSidebar {
    padding: 10px 20px;
    display: flex;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
  }
  .loginLogin {
    margin-right: auto;
  }
  .contentBottom {
    padding-left: 50px;
  }
  #categories a,
  .footerRow .links a {
    font-size: 18px;
  }
  .spacer {
    padding: 0.4rem 0;
  }
  #productGrid .title {
    font-size: 22px;
  }
  #productGrid .price {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .newsletterImage {
    display: none;
  }
  .newsInner .wrapper {
    margin: auto;
    width: fit-content;
    text-align: center;
  }
  .newsInner {
    width: 600px;
    height: 400px;
  }
  .newsInner form button {
    padding: 5px;
    min-width: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .newsInner .wrapper {
    margin: auto;
    width: fit-content;
    text-align: center;
  }
  .newsInner {
    width: fit-content;
    max-width: 90vw;
    max-height: 90vh;
    height: 380px;
  }
  .newsInner form button {
    padding: 5px;
    min-width: 100px;
    height: 40px;
    max-width: 50%;
    margin: auto;
  }
  .imageMisja {
    padding: 0 10px;
  }
  #misjaText {
    padding: 0 25px;
    margin-top: 20px;
  }
  #misjaText p {
    font-size: 18px;
  }
  .newsInner .rightSideNewsletter h2 {
    font-size: 50px;
  }
  .newsInner p.top {
    font-size: 11px;
    margin-top: 2rem;
  }
  .newsInner .bottom {
    margin: 2rem 0;
    font-size: 16px;
  }
  .newsInner .close {
    top: 0;
    right: 10px;
  }
  .newsInner form {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .newsInner form input {
    height: 40px;
    width: 100%;
    text-align: center;
  }
  .newsletterBG {
    top: 0;
  }
}

@media only screen and (max-width: 991px) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }
  #categories,
  #topHeader {
    display: none;
  }
  .mobileMenu {
    display: block;
    padding: 10px;
  }
  #header {
    display: none;
  }
  .mobileRight i {
    font-size: 24px;
  }
  .footerRow {
    flex-direction: column;
    justify-content: center;
  }
  .footerRow .socials {
    justify-content: center;
    margin: 10px 0;
  }
  .footerRow .links {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
  }
  .footerRow .links a {
    text-align: center;
  }
  #productGrid .title {
    font-size: 18px;
  }
  #productGrid .price {
    font-size: 16px;
  }
}
.filterMobile {
  display: none;
}
/* .mobileCategoriesFilter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding-left: 0;
} */
#transition-right {
  animation: fadeinRight 0.3s ease-in-out;
}
.search #inputGroup-sizing-sm {
  border-radius: 0 !important;
}
.inputHeader {
  height: 100%;
  border-radius: 0 !important;
  border-color: #484848 !important;
}
.search .form-control {
  border-color: #484848 !important;
  border-radius: 0 !important;
}
.search .input-group-text {
  border-color: #484848 !important;
}
#transition-left {
  animation: fadeinLeft 0.3s ease-in-out;
}

@keyframes fadeinRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeinLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader .lds-dual-ring {
  animation: spin 1s linear infinite;
}

.loader {
  max-width: 350px;
  margin: auto;
  height: 100%;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.faq {
  background: url("/public/bootsFaq.jpg") repeat;
}

.bgImage {
  display: none;
}

@media screen and (min-width: 768px) {
  #contactBG {
    position: relative;
    padding-left: 0 !important;
  }

  .bgImage {
    position: absolute;
    width: 45%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    display: block;
  }

  .contactContent {
    width: 80%;
    padding: 20px;
    background: rgb(255 255 255 / 92%);
    border-radius: 5px;
  }
}

@media screen and (min-width: 2000px) {
  #productGrid .productImg {
    min-height: 25vh;
  }
  #sidebar .categories {
    font-size: 30px;
  }
  #productGrid .title {
    font-size: 35px;
  }
  .brand label {
    font-size: 30px;
  }
  #categories a {
    font-size: 40px;
  }
  #productGrid .price {
    font-size: 30px;
  }
  .topCat {
    font-size: 40px;
  }
  .shopSearch {
    font-size: 30px;
  }
  .footerRow .links a {
    font-size: 30px;
  }
}
